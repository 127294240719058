import { useIntl } from 'react-intl';
import messages from './confirmLeave.messages';
import { Props } from '../types';
import Dialog from '..';

const ConfirmLeaveDialog = ({
  onConfirm,
  onCancel,
  dataTestId,
}: Partial<Props>) => {
  const intl = useIntl();

  return (
    <Dialog
      dataTestId={dataTestId}
      title={intl.formatMessage(messages.confirmLeaveTitle)}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <p>{intl.formatMessage(messages.confirmLeaveText)}</p>
    </Dialog>
  );
};

ConfirmLeaveDialog.defaultProps = {
  dataTestId: 'confirmLeave',
};

export default ConfirmLeaveDialog;
